import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { changeLocale } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { ModalChangeLanguageQuery } from './__generated__/ModalChangeLanguageQuery'
import './style.css'

const ModalChangeLanguage = () => {
    const image = styled.img`
        width: 10.66px;
        height: 24px;
        blend: Pass through;
    `
    const [isShown, setModalStatus] = useState(true)
    const [hasPreferedLanguage, setHasPreferedLanguage] = useState(false)

    const onToggle = () => {
        setModalStatus(!isShown)
    }

    const changeLangage = (langCode = 'fr') => {
        changeLocale(langCode)
        setModalStatus(!isShown)
        localStorage.setItem('prefered-lang', langCode)
    }

    // This runs when the page is loaded.
    useEffect(() => {
        if (localStorage.getItem('prefered-lang')) {
            setHasPreferedLanguage(true)
        }
    }, [])

    const Bouton = styled.button`
        margin: 8px 8px 16px 78px;
        display: flex;
        width: 120px;
        align-items: center;
    `

    const TitleMg = styled.h3`
        margin-left: 20%;
        margin-right: 20%;
        text-align: center;
    `
    const TitleFr = styled.p`
        margin-left: 10%;
        margin-right: 20%;
        text-align: center;
    `

    const { strapiGlobal } = useStaticQuery<ModalChangeLanguageQuery>(
        graphql`
            query ModalChangeLanguageQuery {
                strapiGlobal {
                    flagLogoMg {
                        localFile {
                            publicURL
                        }
                    }
                    ChooseLangDialog {
                        dialogTitle
                        dialogTitle_mg
                        availableLang {
                            label
                            code
                            flag {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        `,
    )

    return (
        <div>
            {!hasPreferedLanguage && (
                <Modal className={'centerdModal'} isOpen={isShown} toggle={onToggle}>
                    <ModalBody>
                        <TitleMg> {strapiGlobal?.ChooseLangDialog?.dialogTitle_mg} </TitleMg>
                        <TitleFr>{strapiGlobal?.ChooseLangDialog?.dialogTitle} </TitleFr>
                        {strapiGlobal?.ChooseLangDialog?.availableLang?.map((item) => (
                            <Bouton
                                key={`lang-${item}`}
                                onClick={() => changeLangage(item?.code ?? 'fr')}
                                style={{ padding: '0.09rem', justifyContent: 'space-between' }}
                            >
                                <img
                                    style={{
                                        width: 33,
                                        height: 18,
                                        padding: 0,
                                        left: 66.67,
                                        alignItems: 'center',
                                        marginBottom: '0',
                                        paddingInline: 5,
                                    }}
                                    src={item?.flag?.localFile?.publicURL ?? ''}
                                ></img>
                                {item?.label}
                            </Bouton>
                        ))}
                    </ModalBody>
                </Modal>
            )}
        </div>
    )
}

export default ModalChangeLanguage
